<template>
  <div class="default-layout" :style="accessToken == null ? 'margin-left: 0px;' : ''">
    <Sidebar />
    <div class="content-panel" :style="accessToken == null ? 'max-width: 100%;' : ''">
      <Topbar />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Topbar from '@/layout/components/Topbar'
import Sidebar from '@/layout/components/Sidebar'
import { mapState } from 'vuex'
export default {
	name: 'Default',
	components: {
		Topbar,
		Sidebar
	},
	computed: {
		...mapState(['accessToken']),
		logo () {
			return this.sidebarExpandido ? 'ltc-chile.svg' : 'ltc-chile-mini.svg'
		}
	}
}
</script>
