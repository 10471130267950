<template>
	<div class="login-layout">
		<slot></slot>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import '@/assets/css/login.css'

export default {
	  name: 'Login',
	  components: {
	  },
	  computed: {
		  ...mapState(['accessToken']),
		  logo () {
			  return this.sidebarExpandido ? 'ltc-chile.svg' : 'ltc-chile-mini.svg'
		  }
	  }
}
</script>
