import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'

import Default from '@/layout/Default'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Multiselect from 'vue-multiselect'
import VueSweetalert2 from 'vue-sweetalert2'
import Loading from 'vue-loading-overlay'
import SmartTable from 'vuejs-smart-table'

import 'material-icons/iconfont/material-icons.css'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'vue-loading-overlay/dist/vue-loading.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import '@/assets/css/fonts.css'
import '@/assets/css/style.css'
import JQuery from 'jquery'

Vue.use(VueAxios, axios)

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(SmartTable)
Vue.use(VueSweetalert2)

Vue.component('Multiselect', Multiselect)
Vue.component('Loading', Loading)
Vue.component('DefaultLayout', Default)

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')

window.$ = JQuery

Vue.mixin({
	methods: {
		estrellasToptalent (nbt, clases = '') {
			let html = ''
			const ttalent = nbt * 0.5 + 0.5
			const half = ttalent % 1
			html += ('<i class="material-icons ' + clases + '">star_rate</i>').repeat(
				ttalent - half
			)
			if (half) {
				html += '<i class="material-icons ' + clases + '">star_half</i>'
			}
			if (ttalent < 5) {
				html += (
					'<i class="material-icons-outlined ' +
          clases +
          '">grade</i>'
				).repeat(5 - ttalent)
			}
			console.log(html)
			return html
		},
		inArray (search, array) {
			let existe = false
			array.forEach((value, key) => {
				const compare = value.id ? parseInt(value.id) : parseInt(value)
				if (compare === parseInt(search)) {
					existe = true
				}
			})
			return existe
		},
		showModal (id, mostrar) {
			if (mostrar) {
				window.$('#' + id).fadeIn()
			} else {
				window.$('#' + id).fadeOut()
			}
		}
	}
})
