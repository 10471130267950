<template>
  <div class="table-responsive" :class="clases">
    <table :class="claseTabla">
      <tbody>
        <tr>
          <td>
            <div class="p-3 text-center">
              <p class="display-1 text-center my-3" style="opacity:.3;">
                <i class="material-icons">{{ icono }}</i>
              </p>
              <p class="h5 font-500 text-gris-01 mb-3">{{ texto }}</p>
              <slot />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <loading
      :active.sync="cargando"
      :can-cancel="false"
      :is-full-page="false"
    ></loading>
  </div>
</template>

<script>
export default {
	name: 'EmptyTable',
	props: {
		cargando: {
			type: Boolean,
			required: false,
			default: false
		},
		clases: {
			type: String,
			required: false,
			default: ''
		},
		claseTabla: {
			type: String,
			required: false,
			default: 'table'
		},
		icono: {
			type: String,
			required: false,
			default: ''
		},
		texto: {
			type: String,
			required: false,
			default: 'No se han encontrado registros.'
		}
	}
}
</script>
